<template>
  <div class="apply_speaker">
    <div class="artItem">
      <h1>姓名</h1>
      <van-field v-model="name" name="name" label placeholder="请您输入姓名" />
    </div>
    <div class="artItem">
      <h1>电话</h1>
      <van-field v-model="phone" name="phone" label placeholder="请您输入电话" />
    </div>
    <div class="artItem">
      <h1>行业</h1>
      <van-field
        v-model="result"
        is-link
        readonly
        name="picker"
        label
        placeholder="点击选择所在行业"
        @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" position="bottom">
        <van-picker :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
      </van-popup>
    </div>
    <div class="artItem">
      <h1>地区</h1>
      <van-field
        v-model="area"
        is-link
        readonly
        name="area"
        label
        placeholder="请您输入所在地区"
        @click="showArea = true"
      />
      <van-popup v-model:show="showArea" position="bottom">
        <!-- <van-picker :columns="columnsarea" @confirm="onConfirmarea" @cancel="showArea = false" /> -->
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="columnsarea"
          @close="showArea = false"
          @finish="onConfirmarea"
        />
      </van-popup>
    </div>
    <div class="submitHold">
      <van-button :loading="submitLoading" round plain class="submit" type="info" @click="submit">提交</van-button>
    </div>
    <div style="height:3rem;"></div>
    <!-- 底部导航 -->
    <index-footer></index-footer>
    <van-overlay :show="loadShow" style="z-index:900;">
      <div class="wld-wrapper" @click.stop>
        <div class="wld-block">
          <!-- <van-loading type="spinner" /> -->
          <van-loading size="24px" vertical>加载中...</van-loading>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="loadSuc" style="z-index:900;">
        <div class="fenxiang">
          <img src="../../public/statics/images/my/jian.png" />
          <span>点击分享</span>
        </div>
      <div class="apply-suc-wrapper" @click.stop>
        <div class="apply-suc-block" @click="toPath('/my', {})">
          <img src="../../public/statics/images//icon_suc.png" />
        </div>
      </div>
    </van-overlay>
    <!-- 立即注册弹框 -->
    <van-popup v-model:show="showZhuce" :close-on-click-overlay="false" class="zhuTan">
      <div class="zhuce">
        <div class="top">
          <h2>运城发声人平台</h2>
          <span>打通宣传的最后一公里</span>
          <span>让重要信息触达更多人</span>
        </div>
        <div class="bottom">
          <div class="btn" @click="toZhu">立即注册</div>
          <span>注册成为发声人</span>
          <div class="des">
            <span class="left">分享内容获积分</span>
            <span>积攒积分换奖品</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import indexFooter from "./footer.vue";
import { Notify } from "vant";
import Cookies from "js-cookie";

export default {
  name: "apply_speaker",
  setup() {
    const onClickLeft = function() {
      history.back();
    };
    return {
      onClickLeft
    };
  },
  data() {
    return {
      showZhuce: true,
      loadSuc: false, //申请成功 弹层控制
      loadNum: 0,
      loadShow: false,
      submitLoading: false, // 按钮加载状态
      showPicker: false,
      showArea: false,
      result: "",
      columns: [],
      columnsarea: [],
      cascaderValue: "",
      name: "",
      phone: "",
      industry: "",
      area: "",
      areaId: "",
      industryList: []
    };
  },
  components: {
    indexFooter
  },
  created() {
    if(this.$route.query.boo){
      this.showZhuce = false
    } else {
      this.showZhuce = true
    }
    // window.document.title = '“新文化符号”出海'
    let that = this;
    this.loadNum++;
    this.loadShow = true;
    let ps = {
      token: Cookies.get("spToken")
    };
    that.$api.index.getArea(ps).then(function(response) {
      if (response.code === 200) {
        that.loadNum--;
        if (that.loadNum == 0) {
          that.loadShow = false;
        }
        // console.log(response.result)
        that.columnsarea = response.result;
      }
    });
    this.loadNum++;
    this.loadShow = true;
    that.$api.index.getIndustry(ps).then(function(response) {
      if (response.code === 200) {
        that.loadNum--;
        if (that.loadNum == 0) {
          that.loadShow = false;
        }
        // console.log(response.result)
        that.industryList = response.result;
        // console.log(that.industryList)
        let arr = [];
        that.industryList.forEach(function(item, idx) {
          arr.push(item.name);
        });
        that.columns = arr;
        // console.log(that.columns)
      }
    });
  },
  methods: {
       shareFun() {
        var that = this;
        var tempurl = location.href;
        const url = location.href.split("#")[0];
        let routeData = that.$router.resolve({
          path: '/my',
          // query: {
          //   speaker_id: 7987978
          // }
        })
        // console.log(routeData.href)
        const shareUrl = that.$publicData.domain + routeData.href
        // console.log(shareUrl)
        that.$api.index.getShare({url: tempurl}).then(res => {
            if (res.code == 200) {
                that.shareConfig = res.result;
                wx.config({
                    debug: that.shareConfig.debug,
                    beta: that.shareConfig.beta,
                    jsApiList: that.shareConfig.jsApiList,
                    appId: that.shareConfig.appId,
                    nonceStr: that.shareConfig.nonceStr, // 随机串
                    timestamp: parseInt(that.shareConfig.timestamp), // 时间戳
                    signature: that.shareConfig.signature // 签名
                });
                // console.log('Iframe ---微信分享设置---开始');
                // 分享
                wx.ready(function () {
                    var shareDataA = {
                        // title: that.$publicData.shareTitle, // 分享标题
                        title: '我已成为运城发声人，我为家乡代言', // 分享标题
                        desc: that.$publicData.shareDesc, // 分享描述
                        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.shareImg, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };

                    var shareDataB = {
                        // title: that.$publicData.shareTitle, // 分享标题
                        title: '我已成为运城发声人，我为家乡代言', // 分享标题
                        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: that.$publicData.shareImg, // 分享图标
                        success: function () {
                        },
                        cancel: function (e) {
                        }
                    };
                    wx.updateAppMessageShareData(shareDataA); // 分享给朋友
                    wx.updateTimelineShareData(shareDataB); // 分享到朋友圈
                    // console.log('Iframe ---微信分享设置---结束');
                });
            }
            
        });
    },
    // 点击立即注册
    toZhu() {
      this.showZhuce = false;
      // this.$router.push({
      //   path:"/apply"
      // })
    },
    onConfirm(value) {
      this.result = value;
      this.showPicker = false;
    },
    onConfirmarea({ selectedOptions }) {
      // this.area = selectedOptions[selectedOptions.length-1].text
      this.area = selectedOptions.map(option => option.text).join("/");
      this.areaId = selectedOptions[selectedOptions.length - 1].value;
      this.showArea = false;
    },
    submit() {
      let that = this;
      that.submitLoading = true;
      that.industryList.forEach(function(item, idx) {
        if (item.name == that.result) {
          that.industry = item.id;
        }
      });
      let ps = {
        name: this.name,
        phone: this.phone,
        industry: this.industry,
        area: this.areaId,
        token: Cookies.get("spToken")
      };
      console.log(ps);
      that.$api.my.apply(ps).then(function(response) {
        that.submitLoading = false;
        if (response.code === 200) {
          that.shareFun();
          // console.log(response.result)
          // Notify({ type: "success", message: "操作成功" });
          // that.$toast("申请成功")
          that.loadSuc = true;
          // setTimeout(() => {
          //   that.toPath("/my", {});
          // }, 1500);
        }
      });
      // that.toPath("/my", {});//注掉
    },
    toPath(url, obj) {
      if (this.$route.fullPath == url) {
        return;
      }
      if (this.$route.path == url) {
        this.$router.push({
          path: url,
          query: obj
        });
        window.location.reload();
        return;
      }
      this.$router.push({
        path: url,
        query: obj
      });
    }
  },
  mounted() {
    this.shareFun()
  }
};
</script>
<style>
.apply_speaker .artItem .van-cell {
  padding-left: 0;
  padding-right: 0;
}
.apply_speaker .artItem {
  padding: 0.4rem 0.68rem;
  padding-right: 0.52rem;
  padding-bottom: 0.1rem;
  width: 9.2rem;
  margin: 0 auto;
  box-shadow: 0 0 20px 0 rgba(50, 93, 205, 0.15);
  margin-top: 0.32rem;
}
.apply_speaker .artItem h1 {
  font-size: 0.37rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  position: relative;
}
.apply_speaker .artItem h1:before {
  content: "*";
  position: absolute;
  top: 3px;
  left: -15px;
  color: #e83333;
}
.apply_speaker .artItem .upImg {
  width: 80px;
  height: 80px;
  background: #f8f8f8;
  border: none;
  color: rgba(0, 0, 0, 0.8);
}
.apply_speaker .submitHold {
  width: 6rem;
  margin: 0 auto;
  margin-top: 1rem;
}
.apply_speaker .submitHold .submit {
  width: 100%;
  border: none;
  background: #0b47ac;
  font-size: 0.45rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.fenxiang{
  position: absolute;
  right: 0;
  width: 4rem;
  height: 2.7rem;
}
.fenxiang img{
  width: 1.77rem;
  height: 1.8rem;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
}
.fenxiang span{
  font-size: 0.37rem;
  font-weight: bold;
  display: block;
  color: #fff;
  position: absolute;
  right: 2.3rem;
  top: 2.7rem;
  opacity: 0.8;
}
.apply-suc-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.apply-suc-block {
  width: 8.5rem;
  height: 9rem;
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.apply_speaker .zhuTan {
  background: transparent;
}
.apply_speaker .zhuce {
  position: relative;
  width: 8.25rem;
  height: 9.84rem;
  background: url(../../public/statics/images/my/tanback.png) no-repeat;
  background-size: 100%;
}
.apply_speaker .zhuce .top{
  width: 100%;
  position: absolute;
  top: 0.9rem;
  text-align: center;
  color: #fff;
}
.apply_speaker .zhuce .top h2{
  font-size: 0.64rem;
  margin-bottom: 0.3rem;
}
.apply_speaker .zhuce .top span{
  display: block;
  font-size: 0.37rem;
}
.apply_speaker .zhuce .bottom{
  width: 100%;
  position: absolute;
  bottom: 0.3rem;
  text-align: center;
  color: #fff;
}
.apply_speaker .zhuce .bottom .btn{
  width: 3.94rem;
  height: 0.93rem;
  background: #fff;
  border-radius: 0.47rem;
  text-align: center;
  font-size: 0.43rem;
  color: #2048A7;
  line-height: 0.93rem;
  margin: 0 auto 0.2rem;
}
.apply_speaker .zhuce .bottom .des span{
  font-size: 0.35rem;
}
.apply_speaker .zhuce .bottom .des .left{
  margin-right: 0.5rem;
}
</style>
